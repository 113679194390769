<template>
  <div class="index-page">
    <TheBanner mobileImage="/images/home/m-rapaz-feliz.png"
      desktopImage="/images/home/rapaz-feliz.png" imageAlt="Home Banner" textContent="O MeLembre é um serviço de comunicação entre empresas e pessoas. Facilitamos seu acesso a notificações e
        faturas, assim você mantém sua vida em ordem." buttonText="Saiba mais" @button-clicked="buttonClicked()">
      <template #title>
        <h1 class="banner-title">Pode esquecer, <br> a gente te lembra</h1>
      </template>
    </TheBanner>
    <HomeHowItWorks />
    <HomeOurPartners />
    <HomeOurChannels 
      iconsPath="home/our-channels/" 
      mobilePath="/images/home/our-channels/m-moca-feliz.png"
      desktopPath="/images/home/our-channels/moca-feliz.png" 
    />
    <HomeAboutCompany />
    <HomeAchievedResults />
    <HomeInTheMedia />
  </div>
</template>
<script setup lang="ts">
definePageMeta({
  title: 'Some Page'
});

const router = useRouter();
const buttonClicked = () => router.push('/empresa');
</script>

<style scoped lang="scss"></style>