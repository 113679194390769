
import { defineStore } from 'pinia'
import { ContentCardItem } from "~/types";

export const useHowItWorksStore = defineStore('howItWorksStore', {
  state: () => ({
    steps: [
      {
        id: 1,
        mediaSrc: "home/how-it-works/shake-hands.png",
        title: "Emoji de mãos amarelas se apertando",
        text: "Empresa parceira contrata o MeLembre para notificar seus clientes. A partir disso, nós cuidamos de tudo!"
      },
      {
        id: 2,
        mediaSrc: "home/how-it-works/airplane-paper.png",
        title: "Desenho de avião de papel branco com estrelas em volta",
        text: "Enviamos, sempre que necessário, comunicações aos clientes do parceiro para notificá-los sobre produtos e/ou serviços contratados."
      },
      {
        id: 3,
        mediaSrc: "home/how-it-works/notification.png",
        title: "Emoji de sineta amarela",
        text: "Como resultado, você recebe notificações e não deixa nenhum assunto importante passar batido. E o melhor: tudo isso sem custo algum para você!"
      },
    ] as Array<ContentCardItem>
  }),

  getters: {
    getSteps: (state) => state.steps,
  },
})